.hero{
  height: 120vh;
  width: 100%;
  background-image: url(./Images/svgexport-1.svg);
  background-color: #fff;
  background-size: cover;

}


.navBar{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2vh;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.generalNav{
  position: relative;
}

.mobileNav{
  display: none;
}
.logo{
  height: 12vh;
}


.mobileMenu{
  display: none;
}


.menus li{
  color: white;
  display: inline;
  margin-left: 15px;
  margin-right: 15px;
}

.navSignInBtn{
  border: none;
  color: white;
  margin-left: 10px;
  padding: 8px 35px;
  border-radius: 5px;
  background-color: #700c22;
  text-decoration: none;
}

.menuCont{
  justify-content: space-between;
  align-items: center;
  display: flex;
}


.laftNavIcon{
  margin-left: 10px;
  
}

.laftIcon{
  margin-right: 2px;
  font-size: 12px;
  margin-bottom: 1px;
  
}

.leftHero{
  padding-left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;

}


.heroCont{
  width: 100%;
  display: flex;
  align-items: center;
  height: 90vh;
}

.heroCont h1{
  font-size: 60px;
  font-weight: bolder;
  color: white;
}

.heroCont p{
  color: white;
}

.aaone{
  color: #f7ec34;
  text-decoration: none;
}

/*  */

.rightHero{
  width: 50%;
  position: relative;
  height: 90%;
  margin-right: 10%;
}

.leftHeroBtn{
  width: fit-content;
  padding: 10px 70px;
  border: none;
  background-color: #700c22;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  text-decoration: none;
}



.shapeOne{
  position: absolute;
  top: 5vh;
  right: 20%;
}


.shapeThree{
  position: absolute;
  top: 20vh;
  right: 0;
}

.shapeFour{
  position: absolute;
  bottom: 0px;
  left: 0;
}


.shapeTwo{
  position: absolute;
  bottom: 0;
  right: 0;
}


.contOne{
  background-image: url(./Images/containers.jpg);
  background-size: cover;
  background-position: 80%;
  height: 50%;
  width: 40%;
  z-index: 1;
  position: absolute;
  top: 10vh;
  left: 10%;
  border-radius: 0px 80px 0px 80px;
}

.contTwo{
  background-image: url(./Images/biker.jpg);
  background-size: cover;
  background-position: center;
  height: 60%;
  width: 35%;
  z-index: 1;
  position: absolute;
  top: 30vh;
  left: 55%;
  border-radius: 80px 00px 80px 0px;
}


.navLinks{
  color: white;
  text-decoration: none;
  cursor: pointer;
}


.contactCard{
  height: 15vh;
  background-color: white;
  width: 47%;
  position: absolute;
  z-index: 2;
  border-radius: 50px 0px 50px 0px;
  bottom: 4vh;
  left: 11%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactCard p{
  color: gray;
  font-weight: bolder;
  font-size: 14px;
  margin-bottom: 0px;
}

.contactCard h4{
  margin-top: 0px;
  color: #700c22;
}

.contactCardLeft{
  height: 50px;
  width: 50px;
  background: rgba(238,74,98,.1);
  color: #700c22;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

}



/*  */

.privacyPolicy{
  padding-left: 5%;
  padding-top: 5vh;
}

/* features */

.features{
  background-color: #fff;
  height: 100vh;
}

.featuresCont{
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresTop{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  flex-direction: column;
  margin-bottom: 50px;
}

.featuresTop h1{
  color: #000a16;
  font-weight: bold;
}

.featuresTop P{
  margin: 0;
  color: #f8941f;
}


.featuresOne{
  height: 55vh;
  width: 30%;
  background-color: #f8941f;
  border-radius: 5px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}


.featuresIconCont{
  height: 90px;
  width: 90px;
  background-color: #111822;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}


.featuresIconCont2{
  height: 90px;
  width: 90px;
  background-color: #111822;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ab69d;
  font-size: 30px;
}

.featuresIconCont3{
  height: 90px;
  width: 90px;
  background-color: #111822;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4664e4;
  font-size: 30px;
}

.featuresIconCont4{
  height: 90px;
  width: 90px;
  background-color: rgba(238,74,98,.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7ec34;
  font-size: 30px;
}


.featuresH5{
  margin-top: 10px;
  color: white;
}


.featuresP{
  text-align: center;
  color: #fff;
  font-size: 14px;
}


.featuresTwo{
  height: 55vh;
  width: 30%;
  background-color: #4664e4;  
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 5px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.featuresFour{
  height: 55vh;
  width: 30%;
  background-color: #1ab69d;
  margin-left: 30px;
  border-radius: 5px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}


.featuresThree{
  height: 55vh;
  width: 20%;
  background-color: #000a16;
  border-radius: 5px;
  padding: 2%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/* about */

.aboutSection{
  height: 80vh;
  display: flex;
  background-color: #111822;
  padding-left: 10%;
  padding-top: 6vh;
}

.aboutOne{
  width: 50%;
  height: 100%;
}

.aboutTwo{
  width: 50%;
  height: 100%;
  padding-left: 15%;
}

.imageFour{
  height: 60%;
  border-radius: 10px;
  position: absolute;
  top: 10vh;
  
}

.downloadLinkBtn{
  text-decoration: none;
  color: white;
  font-size: 11px; 
  background-color: #111822;
  padding: 5px 20px;
  border-radius: 5px;
}

.downloadLinkBtn2{
  text-decoration: none;
  color: #1ab69d;
  font-size: 11px;
  background-color: #111822;
  padding: 5px 20px;
  border-radius: 5px;
}

.downloadLinkBtn3{
  text-decoration: none;
  color: white; 
  font-size: 11px;
  background-color: #111822;
  padding: 5px 20px;
  border-radius: 5px;
}

.downloadLinkBtn4{
  text-decoration: none;
  color: black;
  font-size: 11px;
  background-color: #f7ec34;
  padding: 5px 20px;
  border-radius: 5px;
}

.aboutOne{
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.aboutTwo{
  padding-right: 10%;
}

.aboutImg2{
  position: absolute;
  top: 0;
  left: 0;
}

.aboutImg3{
  position: absolute;
  bottom: 10vh;
  right: -20%;
  height: 40%;
}

.aboutImg4{
  position: absolute;
  top: 0;
  left: 0;

}

.aboutDivOne{
  position: absolute;
  top: 0;
  background-color: white;
  right: -20%;
  height: 50%;
  width: 37%;
  z-index: 2;
  border-radius: 10px 40px 10px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aboutDivOne p{
  text-align: center;
  color: #bababa;
}

.aboutDivTwo{
  position: absolute;
  bottom: 15vh;
  left: 0;
  background-color: white;
  height: 17%;
  width: 44%;
  z-index: 2;
  border-radius: 30px 10px 50px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.aboutDivTwo p{
  margin: 0;
  padding: 0;
}

.aboutDivTwo h5{
  color: #11D30D;
  margin: 0;
  padding: 0;
}


.aboutTwo h2{
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.aboutTwo{
  color: #bababa;
}

.aboutTwopara{
  font-size: 14px;
}
/* download */


.sectionDownload{
  height: 140vh;
  background-color: #fff;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
}


.downloadHeader{
  display: flex;
  justify-content: space-between;
}

.downloadCard1{
  background-color: #111822;
  height: 22vh;
  width: 23%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.downloadCard1 p{
  color: white;
}

.downH1{
  color: #1ab69d;
  font-weight: bold;
}

.downH2{
  color: #f7ec34;
  font-weight: bold;
}

.downH3{
  color: #8e56ff;
  font-weight: bold;
}

.downH4{
  color: #f8941f;
  font-weight: bold;
}

.downloadMid{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  padding-bottom: 10vh;
}

.adhiambo{
  height: 70vh;
  background-image: url(./Images/adhiambo.jpg);
  width: 75%;
  background-size: cover;
  border-radius: 0px 100px 0px 100px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  
}

.downImg1{
  position: absolute;
  top: 5vh;
  right: 10%;

}

.downImg2{
  position: absolute;
  top: 15vh;
  right: 5%;
}

.downImg3{
  position: absolute;
  bottom: 0;
  left: 0;
  
}

.playStoreIcon{
  height: 20vh;
  width: fit-content;
}


/* FOOTER */

.footer{
  background-color: #111822;
  background-image: url(./Images/BG-3.a47b2fbe.webp);
  height: 65vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 80px;
}

.footerCont1{
  display: flex;
}

.footerLogo{
  height: 10vh;
  margin-bottom: 20px;
}

.footer p{
  color: #bababa;
  margin: 0;
  padding: 0;
  font-size: 14px;
  
}

.footer h3{
  color: white;
  margin-bottom: 8vh;
}


.footerCont{
  width: 25%;

}

.subsCont{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.footLinks{
  color: #bababa;
  text-decoration: none;
}

.footerCont input{
  height: 6vh;
  border: none;
  font-size: 12px;
  padding-left: 20px;
}

.footerCont button{
  background-color: #700c22;
  border: none;
  height: 6.2vh;
  color: white;
  padding-left: 20px;
  padding-right: 20px;

}

.socialCont{
  display: flex;
  align-items: center;
  margin-top: 2vh;
}

.socials{
  height: 6vh;
}

.socialCont a{
  margin-right: 10px;
}

.pagesFooter{
  padding-left: 5%;
}

.footer hr{
  color: white;
  margin-bottom: none;
  margin-top: 50px;
}

.copyrightText{
  text-align: center;
  font-size: 12px;
}

/* carrer */

.mainCareer{
  height: 15vh;
  background-color: #000a16;
}

.mainContentCareer{
  height: 114vh;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #000a16;
  padding-bottom: 10vh;
  padding-top: 5vh;
  display: flex;
}


.careerLeft{
  width: 40%;
  height: 100%;
  position: relative;
}

.careerRight{
  width: 60%;
  height: 100%;
  padding-top: 10vh;
  padding-left: 13%;
}


.carrLefDiv1{
  height: 37vh;
  width: 45%;
  background-image: url(./Images/girl1.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 100px 100px 0px 100px;
  position: absolute;
  top: 10vh;

}

.carrLefDiv2{
  height: 37vh;
  width: 45%;
  background-image: url(./Images/biker.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 50px 0px 100px 0px;
  position: absolute;
  top: 18vh;
  right: 3%;

}

.carrLefDiv3{
  height: 37vh;
  width: 45%;
  background-image: url(./Images/boy1.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 50px 0px 100px 0px;
  position: absolute;
  bottom: 10vh;
  z-index: 1;
}


.carrLefDiv4{

  height: 37vh;
  width: 45%;
  background-image: url(./Images/girl2.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 0px 50px 100px 100px;
  position: absolute;
  bottom: 2.5vh;
  right: 3%;

}


.careerImage2{
  position: absolute;
  right: -70px;
  top: 7vh;
}

.careerImage1{
  top: 0;
  position: absolute;
  left: 35%;
}

.careerImage3{
  position: absolute;
  bottom: 0px;
  left: -100px;
}

.careerImage4{
  position: absolute;
  bottom: 0px;
  left: -50px;
  z-index: 2;
}


.careerRight h1{
  color: white;
  font-weight: bold;
}

.careerRight h6{
  color: #bababa;
}


.careerRight p{
  color: #bababa;
}



/*  */
.downloadSec{
  width: 100%;
  height: 100%;
  padding-top: 10vh;
}

.downloadSec h1{
  font-size: 50px;
  color: white;
  text-align: center;
  margin-bottom: 10vh;
}

.downloadSec p{
  color: white;
  text-align: center;
}

.downloadGoolgeIcon{
  height: 20vh;
  margin-left: 10%;
}


.downloadDiv{
  display: flex;
  justify-content: center;
}

