@media screen  and (max-width:768px){


    .menus{
        display: none;
    }

    .logo{
        height: 7vh;
    }

    .mobileMenu{
        display: block;
    }

    .mobileMenuIcon{
        color: white;
        font-size: 20px;
    }
    

    .heroCont{
        width: 100%;
        padding-right: 5%;
        display: block;
        height: fit-content;
    }


    .rightHero{
        width: 100%;
        height: 46vh;
    }

    .leftHero{
        width: 100%;
        margin-top: 15vh;
    }
      
    .leftHero h1{
        font-size: 30px;
    }

    .leftHero p{
        font-size: 12px;
    }

    .leftHeroBtn{
        font-size: 12px;
        padding: 9px 40px;
        margin-top: 10px;
    }

    /*  */
    .shapeOne{
        position: absolute;
        top: 5vh;
        right: 20%;
        display: none;
      }
      
      
      .shapeThree{
        position: absolute;
        top: 20vh;
        right: 0;
        display: none;
      }
      
      .shapeFour{
        position: absolute;
        bottom: 0px;
        left: 0;
        display: none;
      }
      
      
      .shapeTwo{
        position: absolute;
        bottom: 0;
        right: 0;
        display: none;
      }


    .contOne{
        background-image: url(./Images/soko.jpg);
        background-size: cover;
        background-position: center;
        height: 66%;
        width: 40%;
        z-index: 1;
        position: absolute;
        top: 4vh;
        left: 10%;
        border-radius: 0px 50px 0px 50px;
    }
  
    .contTwo{
        background-image: url(./Images/duka.jpg);
        background-size: cover;
        background-position: center;
        height: 66%;
        width: 40%;
        z-index: 1;
        position: absolute;
        top: 14vh;
        right: 0;
        border-radius: 50px 0px 50px 0px;
    }

    .contactCard{
        display: none;
    }

    /* features */

    .features{
        height: fit-content;
        padding-bottom: 10vh;
    }

    .featuresTop P{
        margin: 0;
        color: white;
        font-size: 13px;
        text-align: center;
        margin-left: 10%;
        margin-right: 10%;
    }


    .featuresCont{
        display: block;
        margin-left: 10%;
        margin-right: 10%;
    }


    .featuresOne{
        height: 55vh;
        width: 100%;
        background-color: #000a16;
        border-radius: 5px;
        padding: 2%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 4vh;
    }
  
    .featuresTwo{
        height: 55vh;
        width: 100%;
        background-color: #000a16;
        border-radius: 5px;
        padding: 2%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 4vh;
    }
    
    .featuresFour{
        height: 55vh;
        width: 100%;
        background-color: #000a16;
        border-radius: 5px;
        padding: 2%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 4vh;
    }
    
    
    .featuresThree{
        height: 55vh;
        width: 100%;
        background-color: #000a16;
        border-radius: 5px;
        padding: 2%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
    }


    /* about */

    .aboutSection{
        height: fit-content;
        display: block;
        background-color: #111822;
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 2vh;
    }

    .aboutOne{
        width: 100%;
        height: 60vh;

    }

    .aboutImg2{
        position: absolute;
        top: 0;
        left: 0;
        height: 10vh;
    }

    .aboutImg4{
        position: absolute;
        top: 0;
        left: 0;
        height: 10vh;
      
    } 

    .imageFour{
        height: fit-content;
        border-radius: 10px;
        position: absolute;
        top: 5vh;
        left: 5%;
        width: 85%;
        
    }

    .aboutImg3{
        position: absolute;
        bottom: 20vh;
        right: 0%;
        height: 10vh;
    }

    .aboutDivOne{
        position: absolute;
        top: 0;
        background-color: white;
        right: 5%;
        height: 20vh;
        width: 30%;
        z-index: 2;
        border-radius: 10px 30px 10px 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .aboutDivOne p{
        text-align: center;
        color: #bababa;
        font-size: 9px;
        margin-left: 10px;
        margin-right: 10px;

      }

      .aboutDivOne h5{
        text-align: center;
        font-size: 10px;

      }

      .aboutDivOne img{
        height: 5vh;
      }

    .aboutDivTwo{
        position: absolute;
        bottom: 20vh;
        left: 0;
        background-color: white;
        height: 10vh;
        width: 44%;
        z-index: 2;
        border-radius: 20px 5px 40px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .aboutDivTwo p{
        margin: 0;
        padding: 0;
        font-size: 9px;
    }
    
    .aboutDivTwo h5{
        color: #FF214F;
        margin: 0;
        padding: 0;
        font-size: 12px;
    }

    .aboutTwo{
        width: 100%;
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 50px;
    }
    

    .sectionDownload{
        height: fit-content;
        background-color: #000a16;
        padding-top: 40px;
      }

      .downloadHeader{
        display: block;
        justify-content: space-between;
      }

      .downloadCard1{
        background-color: #111822;
        height: 17vh;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 4vh;
      }

      .downloadMid{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 10vh;
      }


      .adhiambo{
        height: 30vh;
        background-image: url(./Images/adhiambo.jpg);
        width: 100%;
        background-size: cover;
        border-radius: 0px 40px 0px 40px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        
      }

      .adhiambo img{
        height: 10vh;
      }
    
      .downImg1{
        position: absolute;
        top: 3vh;
        right: -9%;
        height: 17vh;
      
      }

      .downImg2{
        position: absolute;
        top: 9vh;
        height: 17vh;
        right: -9%;
      }

    .downImg3{
        position: absolute;
        bottom: 2vh;
        height: 10vh;
        left: -9%;
        
    }

    /* footer */

    .footer{
        background-color: #111822;
        background-image: url(./Images/BG-3.a47b2fbe.webp);
        height: fit-content;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 50px;
      }

      .footerCont1{
        display: block;
      }


        .footerCont{
            width: 100%;
            margin-bottom: 3vh;
        
        }
        .footerLogo{
            height: 7vh;
            margin-bottom: 20px;
          }
          .footer h3{
            color: white;
            margin-bottom: 2vh;
          }

          .pagesFooter{
            padding-left: 0%;
          }
          .copyrightText{
            text-align: center;
            font-size: 12px;
            padding-bottom: 50px;
          }
    /* career pages */
    
    .mainContentCareer{
        height: fit-content;
        padding-left: 10%;
        padding-right: 10%;
        background-color: #000a16;
        padding-bottom: 10vh;
        padding-top: 0vh;
        display: block;
    }
    .careerLeft{
        width: 100%;
        height: 79vh;
        position: relative;
    }
      
      .careerRight{
        width: 100%;
        height: 100%;
        padding-top: 10vh;
        padding-left: 0%;
      }
      .careerImage1{
        top: 0;
        position: absolute;
        left: 35%;
        height: 10vh;
      }
      .careerImage2{
        position: absolute;
        right: -20px;
        top: 7vh;
        height: 20vh;
      }

      .carrLefDiv1{
        height: 30vh;
        width: 47%;
        background-image: url(./Images/girl1.jpg);
        background-size: cover;
        background-position: center;
        border-radius: 100px 100px 0px 100px;
        position: absolute;
        top: 6vh;
      
      }

      .carrLefDiv2{
        height: 30vh;
        width: 47%;
        background-image: url(./Images/biker.jpg);
        background-size: cover;
        background-position: center;
        border-radius: 50px 0px 80px 0px;
        position: absolute;
        top: 14vh;
        right: 0%;
      
      }
      .carrLefDiv3{
        height: 30vh;
        width: 47%;
        background-image: url(./Images/boy1.jpg);
        background-size: cover;
        background-position: center;
        border-radius: 50px 0px 80px 0px;
        position: absolute;
        bottom: 10vh;
        z-index: 1;
    }
    .carrLefDiv4{

        height: 30vh;
        width: 47%;
        background-image: url(./Images/girl2.jpg);
        background-size: cover;
        background-position: center;
        border-radius: 0px 50px 100px 100px;
        position: absolute;
        bottom: 2vh;
        right: 0%;
      
    }
    .careerImage3{
        position: absolute;
        bottom: 0px;
        left: -30px;
        height: 20vh;
    }
    .careerImage4{
        position: absolute;
        bottom: 0px;
        left: -50px;
        z-index: 2;
        height: 17vh;
    }
      
    .mobileNav{
      display: block;
      height: 100vh;
      background-color: #FF214F;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 33;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 3vh;
    }

    .mobileNavTop{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mobileMenuIcon2{
      color: white;
      font-size: 30px;
    }
    .menus2 li{
      color: white;
      list-style-type: none;
      margin-left: 0px;
      font-size: 19px;
    }

    .menus2{
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .navSignInBtn{
      border: none;
      color: #FF214F;;
      padding: 8px 35px;
      border-radius: 5px;
      background-color: white;
      text-decoration: none;
      margin-left: 0px;
    }

    .downloadSec h1{
      font-size: 30px;
      color: white;
      text-align: center;
      margin-bottom: 5vh;
    }

    .downloadGoolgeIcon{
      height: auto;
      width: 90%;
      margin-left: 0%;
      margin-bottom: 20vh;
      margin-left: 5%;
      margin-right: 5%;
    }

      
  
    
}